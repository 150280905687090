<template>
  <div class="tree_box">
    <div class="line_tree_box" style="position: relative">
      <div class="mainInfo">
        <!-- 第一列内容可插槽 - 供配置页面单选框、复选框一列使用 -->
        <slot name="firstColumn"></slot>
        <!-- 多选框 -->
        <span
          v-if="checkbox"
          class="checkbox-span td"
          :style="{ width: checkboxWidth }"
        >
          <el-checkbox
            v-if="Number(lineData.level) === 1 || isConfigCheck === 'true'"
            v-model="checked"
            :disabled="isDisabled"
          ></el-checkbox>
          <!-- 配置子产品扳手 -->
          <span
            v-if="configImgs && ischeck"
            class="bansou"
            @click="childrenConfig"
          >
            <svg
              style="width: 25px; height: 12px; margin-top: 14px"
              aria-hidden="true"
            >
              <use xlink:href="#icon-configQuotation"></use>
            </svg>
          </span>
        </span>
        <!-- 序号 -->
        <span
          v-if="showIndex"
          class="number-span td"
          :style="{ width: numberWidth }"
        >
          {{ lineData["index"] }}
        </span>
        <span
          :class="attr.prop === 'date' ? 'td inlineblock-td' : 'td'"
          v-for="(attr,index) in tableAttr"
          :key="attr.prop"
          :style="{ width: attr.width }"
          @mouseover="showEditImg(attr)"
          @mouseleave="hideEditImg"
        >
          <!-- 产品名称缩进 -->
          <span v-if="attr.prop === 'productname'">
            <span
              :key="index"
              style="display: inline-block; width: 22px; height: 10px"
              :style="{ width: `${indentWidth}px` }"
            ></span>
          </span>
          <!-- 展开收起子级 -->
          <span
            v-if="
              attr.prop === 'productname' &&
              lineData.childproduct &&
              lineData.childproduct.length > 0
            "
            class="expandQuotation"
          >
            <i
              :class="
                expandQuotation ? 'el-icon-arrow-down' : 'el-icon-arrow-right'
              "
              @click="expandQuotationLine"
            ></i>
          </span>
          <!-- 内容 -->
          <span
            v-if="
              //如果是配置页面使用editLineId否则不使用
              !(EditsSave === 'config'
                ? (editGroupIndex &&
                    editGroupIndex === groupIndex &&
                    editLineIndex === lineData.index &&
                    editLineId === lineData.id &&
                    editField === attr.prop) ||
                  (editLineIndex === lineData.index &&
                    editField === attr.prop &&
                    editLineId === lineData.id)
                : (editGroupIndex &&
                    editGroupIndex === groupIndex &&
                    editLineIndex === lineData.index &&
                    editField === attr.prop) ||
                  (editLineIndex === lineData.index && editField === attr.prop))
            "
          >
            <span :class="modifiedFieldsClass(attr.prop)">
              <!-- 列表单价显示'包含' -->
              <span
                v-if="attr.prop === 'listprice' && lineData.bundled === 'true'"
              >
                {{ $t(`label.tabpage.include`) }}
              </span>
              <!-- 列表单价显示币种 -->
              <span
                v-else-if="
                  attr.prop === 'listprice' && lineData.bundled !== 'true'
                "
              >
                {{ currency }} {{ thousands(lineData[attr.prop]) }}
              </span>
              <!-- 额外折扣字段特殊处理 -->
              <span v-else-if="attr.prop === 'additionaldiscount'">
                <span v-if="discountType === currency">{{ currency }}</span>
                {{ thousands(additionaldiscount)
                }}<span v-if="discountType === '%'">%</span>
              </span>
              <!-- 数量 - 显示千分位符号 -->
              <span v-else-if="attr.prop === 'quantity'">
                {{ thousands(lineData[attr.prop]) }}
              </span>
              <span v-else>{{ lineData[attr.prop] }}</span>
            </span>
            <!-- 编辑/锁定按钮 -->
            <span class="editImg">
              <svg
                class="icon editImage"
                aria-hidden="true"
                style="display: none"
                @click="editInline(attr)"
              >
                <use href="#icon-editImg"></use>
              </svg>
              <svg
                class="icon lockedImage"
                aria-hidden="true"
                style="display: none"
              >
                <use href="#icon-lockedImg"></use>
              </svg>
            </span>
          </span>
          <!-- 编辑框 -->
          <div
            v-if="
              EditsSave === 'config'
                ? (editGroupIndex &&
                    editGroupIndex === groupIndex &&
                    editLineIndex === lineData.index &&
                    editLineId === lineData.id &&
                    editField === attr.prop) ||
                  (editLineIndex === lineData.index &&
                    editField === attr.prop &&
                    editLineId === lineData.id)
                : (editGroupIndex &&
                    editGroupIndex === groupIndex &&
                    editLineIndex === lineData.index &&
                    editField === attr.prop) ||
                  (editLineIndex === lineData.index && editField === attr.prop)
            "
            class="editBox"
          >
            <el-input-number
              :min="
                attr.fieldLength !== undefined && attr.fieldLength !== null
                  ? 0 - Math.pow(10, Number(attr.fieldLength)) + 1
                  : min
              "
              :max="
                attr.fieldLength !== undefined && attr.fieldLength !== null
                  ? Math.pow(10, Number(attr.fieldLength)) - 1
                  : max
              "
              :controls="false"
              :precision="
                attr.decimalplaces !== undefined && attr.decimalplaces !== null
                  ? Number(attr.decimalplaces)
                  : precision
              "
              :class="configImgs ? 'input-numberr' : 'input-number'"
              v-model="inputNumber"
            >
            </el-input-number>
            <!-- 额外折扣字段时才显示 -->
            <el-select
              v-model="discountType"
              v-if="attr.prop === 'additionaldiscount'"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span
              class="buttons"
              :style="{
                left: attr.prop === 'additionaldiscount' ? '280px' : '170px',
              }"
            >
              <el-button size="mini" @click="cancel">
                <!-- 取消 -->
                {{ $t("label.ems.cancel") }}
              </el-button>
              <el-button size="mini" type="primary" @click="save(attr)">
                <!-- 保存 -->
                {{ $t("label.save") }}
              </el-button>
            </span>
          </div>
        </span>
        <span class="td buttonList" :style="{ width: btnWidth }">
          <span
            :class="
              btn.click === 'expandQuotation' || btn.click === 'hideQuotation'
                ? 'btn pull-right'
                : 'btn'
            "
            v-for="(btn, idx) in buttonList"
            :key="idx"
            v-show="showButton(btn)"
            @click="handleClick(btn)"
            @mouseover="handMouse(btn)"
          >
            <svg
              class="icon"
              aria-hidden="true"
              v-if="
                btn.click === 'expandQuotation' || btn.click === 'hideQuotation'
              "
            >
              <use :href="btn.img"></use>
            </svg>
            <!-- 非查看折扣计划按钮 -->
            <el-tooltip
              v-else-if="btn.click !== 'discount'"
              class="item"
              effect="dark"
              :content="
                btn.click === 'quotationInfo'
                  ? `${$t('label.includedIn', {
                      parentproductname: lineData.parentproductname,
                    })}`
                  : btn.tip
              "
              placement="top"
            >
              <svg class="icon" aria-hidden="true">
                <use :href="btn.img"></use>
              </svg>
            </el-tooltip>
            <!-- 查看折扣计划按钮 - 带悬浮提示 -->
            <el-popover v-else placement="left-start" trigger="hover">
              <div class="lookDiscount">
                <!-- 头部信息 -->
                <p class="discountDialogTitle">
                  <svg aria-hidden="true">
                    <use xlink:href="#icon-discountImg"></use>
                  </svg>
                  <span> {{ $t("label.discountPlanEditor") }}</span>
                </p>
                <!-- 搜索信息 -->
                <div class="searchDis">
                  <el-form
                    :inline="true"
                    :model="formInline"
                    class="demo-form-inline"
                  >
                    <el-form-item
                      :label="
                        $t('label.partnerCloud.orderingPlatform.discountPlan')
                      "
                    >
                      <el-input
                        v-model="formInline.name"
                        placeholder=""
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="
                        $t('label.partnerCloud.orderingPlatform.discountUnit')
                      "
                    >
                      <el-input
                        v-model="formInline.company"
                        placeholder=""
                      ></el-input>
                    </el-form-item>
                  </el-form>
                </div>
                <!-- 接口获取表单和表格信息 -->
                <div class="quotation-table">
                  <el-table
                    :data="disTable"
                    style="width: 98%"
                    :header-cell-style="{ background: '#FAFAF9' }"
                  >
                    <el-table-column
                      v-for="(item, index) in disTableAttr"
                      :key="index"
                      :label="item.label"
                    >
                      <template slot-scope="scope">
                        <div v-if="item.apiname == 'name'">
                          {{ scope.row.name }}
                        </div>
                        <div v-if="item.apiname == 'lowerbound'">
                          {{ scope.row.lowerbound }}
                        </div>
                        <div v-if="item.apiname == 'upperbound'">
                          {{ scope.row.upperbound }}
                        </div>
                        <div v-if="item.apiname == 'discount'">
                          {{ scope.row.discount }}
                        </div>
                        <div v-if="item.apiname == 'discountamount'">
                          {{ scope.row.discountamount }}
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <svg class="icon" aria-hidden="true" slot="reference">
                <use :href="btn.img"></use>
              </svg>
            </el-popover>
          </span>
        </span>
      </div>
      <!-- 更多信息 -->
      <div class="moreInfoBox" v-if="expandLine">
        <ul class="moreInfo">
          <li v-for="attr in moreQuoteLineFieldList" :key="attr.prop">
            <p>{{ attr.label }}</p>
            <span>{{ lineData[attr.prop] }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 递归显示子级 -->
    <quotation-line
      v-show="
        lineData.childproduct &&
        lineData.childproduct.length > 0 &&
        expandQuotation
      "
      v-for="(childData, idx) in lineData.childproduct"
      :key="idx"
      :quoteId="quoteId"
      :line-data="childData"
      :tableAttr="tableAttr"
      :tootip="tootip"
      :checkAll="checkAll"
      :checkbox="checkbox"
      :showIndex="showIndex"
      :buttonList="buttonList"
      :currency="currency"
      :editGroupIndex="editGroupIndex"
      :editLineIndex="editLineIndex"
      :editField="editField"
      :pricebookId="pricebookId"
      :expandAllLine="expandAllLine"
      :configImgs="configImgs"
      :groupIndex="groupIndex"
      :moreQuoteLineFieldList="moreQuoteLineFieldList"
      @edit="edit"
      @modify="modify"
      @radioLine="radioLine"
      @copyQuotation="copyQuotation"
      @configQuotation="configQuotation"
      @discountPlan="discountPlan"
      @childrenConfig="childrenConfig"
    ></quotation-line>
  </div>
</template>

<script>
import * as quotationApi from "../api";

export default {
  name: "quotationLine",
  props: {
    // 行数据
    lineData: {
      type: Object,
      default: () => {},
    },
    // 字段信息
    tableAttr: {
      type: Array,
      default: () => [],
    },
    // 展开行信息
    moreQuoteLineFieldList: {
      type: Array,
      default: () => [],
    },
    // 悬浮提示是否可用
    tootip: {
      type: Boolean,
      default: true,
    },
    // 是否显示勾选框
    checkbox: {
      type: Boolean,
      default: false,
    },
    // 是否选中全部
    checkAll: {
      type: Boolean,
      default: false,
    },
    // 勾选框一列宽度
    checkboxWidth: {
      type: String,
      default: "30px",
    },
    // 是否显示序号
    showIndex: {
      type: Boolean,
      default: false,
    },
    // 序号一列宽度
    numberWidth: {
      type: String,
      default: "40px",
    },
    // 是否展开全部行信息
    expandAllLine: {
      type: Boolean,
    },
    // 操作按钮
    buttonList: {
      type: Array,
      default: () => [],
    },
    // 按钮一列宽度
    btnWidth: {
      type: String,
      default: "140px",
    },
    // 报价单id
    quoteId: {
      type: String,
      default: "",
    },
    // 币种
    currency: {
      type: String,
      default: "",
    },
    // 正在编辑数据id
    editLineId: {
      type: String,
      default: "",
    },
    // 正在编辑数据所在报价行组下标
    editGroupIndex: {
      type: String,
      default: "",
    },
    // 正在编辑数据下标
    editLineIndex: {
      type: String,
      default: "",
    },
    // 正在编辑数据属性
    editField: {
      type: String,
      default: "",
    },
    //必须配置禁用
    isDisabled: {
      type: Boolean,
      default: false,
    },
    // 配置页面 - 是否勾选,选中后数据才可编辑数量
    ischecked: {
      type: Boolean,
      default: true,
    },
    // 勾选框是否选中
    ischeck: {
      type: Boolean,
      default: false,
    },
    // 价格手册ID
    pricebookId: {
      type: String,
      default: "",
    },
    //报价行组id
    // groupId: {
    //   type: String,
    //   default: "",
    // },
    //配置页面复选框权限
    isConfigCheck: {
      type: String,
      default: "",
    },
    //必须配置置灰不可取消勾选
    selectedd: {
      type: Boolean,
      default: false,
    },
    configImgs: {
      //配置页面小扳手
      type: Boolean,
      default: false,
    },
    // 报价行所在分组下标
    groupIndex: {
      type: Number,
      default: undefined,
    },
    radio: {
      type: String,
      default: "",
    },
    EditsSave: {
      //判断是否是配置页面编辑保存
      type: String,
      default: "",
    },
  },
  data() {
    return {
      indentWidth: "", // 产品名称缩进距离
      checked: false, // 勾选框是否选中
      expandLine: false, // 是否展开报价行信息
      expandQuotation: true, // // 是否展开捆绑包
      min: 0 - Math.pow(10, 18) + 1,
      max: Math.pow(10, 18) - 1,
      precision: 2,
      inputNumber: "",
      additionaldiscount: "", // 额外折扣
      discountType: "", // 折扣类型
      options: [
        {
          label: "%",
          value: "%",
        },
        {
          label: this.currency,
          value: this.currency,
        },
      ],
      // parentData: [], //父产品传参，配置页面用
      // deletable: true, // 报价行可删除
      visible: false, //折扣计划状态

      disTable: [], //折扣计划表格信息
      formInline: {
        name: "",
        company: "",
      },
      schedulename: "",
      disTableAttr: [],
      // quotelineid: "",
    };
  },
  mounted() {
    // 处理额外折扣字段
    this.additionaldiscount = "";
    this.discountType = "";
    this.expandQuotation = true;

    // 处理额外折扣字段
    if (
      this.lineData["discount"] !== undefined &&
      this.lineData["discount"] !== null &&
      this.lineData["discount"] !== "0.00" &&
      this.lineData["discount"] !== ""
    ) {
      this.additionaldiscount = this.lineData["discount"];
      this.discountType = "%";
    } else if (
      this.lineData["additionaldiscountamount"] !== undefined &&
      this.lineData["additionaldiscountamount"] !== null &&
      this.lineData["additionaldiscountamount"] !== ""
    ) {
      this.additionaldiscount = this.lineData["additionaldiscountamount"];
      this.discountType = this.currency;
    }

    this.checked = this.ischeck;
    this.indentWidth =
      this.lineData.childproduct && this.lineData.childproduct.length > 0
        ? 22 * (Number(this.lineData.level) - 1)
        : 22 * Number(this.lineData.level);
  },
  methods: {
    // 展开、关闭行信息
    toggleExpandLine() {
      this.expandLine = !this.expandLine;
    },
    // 字段是否显示编辑过后的橙色
    modifiedFieldsClass(prop) {
      if (prop === "quantity" && this.lineData[`${prop}edit`] === "true") {
        return "modifiedFields";
      } else if (
        prop === "listprice" &&
        this.lineData[`${prop}edit`] === "true"
      ) {
        return "modifiedFields";
      } else if (
        prop === "additionaldiscount" &&
        this.lineData[`${prop}edit`] === "true"
      ) {
        return "modifiedFields";
      } else if (
        prop === "customerprice" &&
        this.lineData[`${prop}edit`] === "true"
      ) {
        return "modifiedFields";
      } else if (
        prop === "customertotal" &&
        this.lineData[`${prop}edit`] === "true"
      ) {
        return "modifiedFields";
      } else {
        return "";
      }
    },
    // 按钮点击事件
    handleClick(btn) {
      // 展开、收起行信息
      if (btn.click === "hideQuotation" || btn.click === "expandQuotation") {
        this.toggleExpandLine();
      } else if (btn.click === "configQuotation") {
        // 配置
        this.$emit(btn.click, this.lineData);
      } else if (btn.click === "deleteQuotation") {
        // 删除
        this.deleteLine();
      } else if (btn.click === "copyQuotation") {
        // 复制
        this.$emit(btn.click, this.lineData);
      } else {
        // 查看折扣计划、查看父产品信息
        this.$emit(btn.click, this.lineData.id);
      }
    },
    // 删除报价行
    deleteLine() {
      this.$emit("loading");
      // 区分有没有id,有的话调用删除接口成功,前端删除数据,再重新计算,没有的话前端直接删除然后重新计算
      if (
        this.lineData.id !== undefined &&
        this.lineData.id !== null &&
        this.lineData.id !== ""
      ) {
        quotationApi
          .deleteQuoteLine({ ids: this.lineData.id })
          .then((res) => {
            if (res.result) {
              this.$message.success(this.$i18n.t("label.ems.delete.success"));
              this.$emit(
                "deleteQuotation",
                this.groupIndex,
                this.lineData.index
              );
            }
          })
          .catch(() => {
            this.$emit("loading");
          });
      } else {
        this.$emit("deleteQuotation", this.groupIndex, this.lineData.index);
      }
    },
    // 设置千分位样式  dj
    thousands(num) {
      return (
        num &&
        (num.toString().indexOf(".") != -1
          ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
              return $1 + ",";
            })
          : num.toString().replace(/(\d)(?=(\d{3}))/g, function ($0, $1) {
              return $1 + ",";
            }))
      );
    },
    // // 配置产品选项
    // configQuotation() {
    //   // 配置捆绑包产品选项
    //   this.parentData.push({
    //     id: this.lineData.id,
    //     listprice: this.lineData.listprice,
    //     product: this.lineData.product,
    //     quantity: this.lineData.quantity,
    //   })
    //   this.$store.commit('parentData', this.parentData)
    //   localStorage.setItem('productId', JSON.stringify(this.lineData.product))
    //   this.quotelineid=this.lineData.id
    //   this.$router.push({
    //     path: `/quotation/configQuotation/${this.pricebookId}/${this.currency}/${this.quoteId}`,
    //     query: {
    //       groupId: this.groupId,
    //       quotelineid: this.lineData.id,
    //     },
    //   })
    // },
    // 配置产品选项
    configQuotation(list) {
      this.$emit("configQuotation", list);
    },
    //配置产品子集配置
    childrenConfig() {
      this.$emit("childrenConfig", this.lineData.optionalsku);
    },
    // 展开/收起子级
    expandQuotationLine() {
      this.expandQuotation = !this.expandQuotation;
    },
    radioLine(radio, id) {
      this.$emit("radioLine", radio, id);
    },
    // 复制报价行
    copyQuotation(id) {
      this.$emit("copyQuotation", id);
    },
    //展示折扣计划
    handMouse(btn) {
      if (btn.click === "discount") {
        let params = {
          discountscheduleid: this.lineData.discountschedule,
          schedulename: this.schedulename,
          discountunit: "",
        };
        quotationApi.showDiscountSchedule(params).then((res) => {
          res.data.tableLabel.forEach((title) => {
            title.prop = title.apiname;
            title.type = title.fieldtype;
            if (title.fieldtype === "c" || title.fieldtype === "P") {
              // 百分比(%)   金额
              if (res.data.discountunit === "金额") {
                this.$set(title, "apiname", "discountamount");
                this.$set(
                  title,
                  "label",
                  `${this.$i18n.t("label.discount")}（${
                    this.$store.getters.getCurrencyCode
                  }）`
                );
              } else {
                // '折扣（%）'
                this.$set(title, "apiname", "discount");
                this.$set(
                  title,
                  "label",
                  this.$i18n.t("label.discount.baifenbi")
                );
              }
            }
          });
          this.disTable = res.data.schedulelist;
          this.disTableAttr = res.data.tableLabel.filter((val) => {
            return val.fieldtype !== "L";
          });
          this.visible = true;
        });
      }
    },
    // 取消展示折扣计划
    // disCancel() {
    // let a=document.querySelector('.dddd');
    // a.style.display="none"
    // },
    discountPlan(id) {
      this.$emit("discountPlan", id);
    },
    // 显示编辑按钮
    showEditImg(attr) {
      // 数量、列表单价、额外折扣字段可编辑
      if (
        attr.prop === "quantity" &&
        attr.editable &&
        this.lineData.quantityeditable === "true" &&
        this.ischecked
      ) {
        if (event.currentTarget.getElementsByClassName("editImage")[0]) {
          event.currentTarget.getElementsByClassName(
            "editImage"
          )[0].style.display = "inline-block";
        }
      } else if (
        attr.prop === "listprice" &&
        attr.editable &&
        this.lineData.priceeditable === "true" &&
        this.ischecked
      ) {
        if (event.currentTarget.getElementsByClassName("editImage")[0]) {
          event.currentTarget.getElementsByClassName(
            "editImage"
          )[0].style.display = "inline-block";
        }
      } else if (
        attr.prop === "additionaldiscount" &&
        attr.editable &&
        this.lineData.nondiscountable === "false" &&
        this.ischecked
      ) {
        if (event.currentTarget.getElementsByClassName("editImage")[0]) {
          event.currentTarget.getElementsByClassName(
            "editImage"
          )[0].style.display = "inline-block";
        }
      } else {
        if (event.currentTarget.getElementsByClassName("lockedImage")[0]) {
          event.currentTarget.getElementsByClassName(
            "lockedImage"
          )[0].style.display = "inline-block";
        }
      }
    },
    // 隐藏编辑按钮
    hideEditImg() {
      if (event.currentTarget.getElementsByClassName("editImage")[0]) {
        event.currentTarget.getElementsByClassName(
          "editImage"
        )[0].style.display = "none";
      }
      if (event.currentTarget.getElementsByClassName("lockedImage")[0]) {
        event.currentTarget.getElementsByClassName(
          "lockedImage"
        )[0].style.display = "none";
      }
    },

    // 千位分隔符转换成正常数字   dj
    StrToNumber(obj) {
      var reg = /^[0-9]+.?[0-9]*$/; //验证字符串是否是数字
      if (!reg.test(obj)) {
        //为字符串 则判断是否存在','
        if ((obj + "").replace(/^\s\s*/, "").replace(/\s\s*$/, "") == "") {
          return 0.0;
        }
        if (obj == undefined) {
          return 0.0;
        }
        if ((obj || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")) {
          obj = obj.replace(/,/gi, "");
          return obj;
        }
      }
      return obj;
    },

    // 显示内联编辑
    editInline(attr) {
      this.inputNumber = "";
      this.discountType = "";

      // 额外折扣字段特殊处理
      if (attr.prop === "additionaldiscount") {
        // 处理额外折扣字段
        if (
          this.lineData["discount"] === "0.00" &&
          this.lineData["additionaldiscountamount"] === "0"
        ) {
          this.inputNumber = "";
          this.discountType = "";
        } else if (
          this.lineData["discount"] !== undefined &&
          this.lineData["discount"] !== null &&
          this.lineData["discount"] !== "0.00" &&
          this.lineData["discount"] !== ""
        ) {
          this.inputNumber = this.lineData["discount"];
          this.discountType = "%";
        } else if (
          this.lineData["additionaldiscountamount"] !== undefined &&
          this.lineData["additionaldiscountamount"] !== null &&
          this.lineData["additionaldiscountamount"] !== ""
        ) {
          this.inputNumber = Number(
            this.StrToNumber(this.lineData["additionaldiscountamount"])
          ); //千分位样式转换成 数字  dj
          this.discountType = this.currency;
        }
      } else {
        this.inputNumber = Number(this.StrToNumber(this.lineData[attr.prop])); //千分位样式转换成 数字   dj
      }

      this.$emit(
        "edit",
        this.groupIndex,
        this.lineData.index,
        attr.prop,
        this.lineData.id
      );
    },
    // 接收编辑子级产品传过来的参数
    edit(groupIndex, index, prop, id) {
      this.$emit("edit", groupIndex, index, prop, id);
    },
    // 保存内联编辑
    save(attr) {
      if (attr.prop === "quantity") {
        // 校验修改值大于0
        if (!(this.inputNumber > 0)) {
          this.$message.warning(this.$i18n.t("label.quantity.greater.than0"));
          return;
        }
        if (this.EditsSave === "config") {
          //校验配置页面产品最大值最小值
          if (this.lineData.maxquantity && this.lineData.minquantity) {
            if (
              this.lineData.maxquantity == 0 ||
              (this.lineData.maxquantity == null &&
                this.inputNumber < this.lineData.minquantity)
            ) {
              this.$message({
                message: this.$i18n.t("label.number.MinCheck", [
                  this.lineData.productname,
                  this.lineData.minquantity,
                ]),
                type: "warning",
              });
              return;
            }
            if (
              this.lineData.minquantity == 0 ||
              (this.lineData.minquantity == null &&
                this.inputNumber > this.lineData.maxquantity)
            ) {
              this.$message({
                message: this.$i18n.t("label.number.MaxCheck", [
                  this.lineData.productname,
                  this.lineData.maxquantity,
                ]),
                type: "warning",
              });
              return;
            }
            if (
              this.lineData.minquantity !== 0 &&
              this.lineData.maxquantity !== 0 &&
              this.lineData.minquantity !== null &&
              this.lineData.maxquantity !== null
            ) {
              if (
                this.inputNumber > this.lineData.maxquantity ||
                this.inputNumber < this.lineData.minquantity
              ) {
                this.$message({
                  message: this.$i18n.t("label.number.MinAndMaxCheck", [
                    this.lineData.productname,
                    this.lineData.minquantity,
                    this.lineData.maxquantity,
                  ]),
                  type: "warning",
                });
                return;
              }
            }
          }
        }
      }
      // 额外折扣字段特殊处理
      if (attr.prop === "additionaldiscount") {
        if (this.discountType === "%") {
          this.$emit(
            "modify",
            this.groupIndex,
            this.lineData.index,
            "discount",
            this.inputNumber
          );
          this.$emit(
            "modify",
            this.groupIndex,
            this.lineData.index,
            "additionaldiscount",
            ""
          );
        } else {
          // 空和币种类型时，都赋值到额外折扣(币种)字段
          this.$emit(
            "modify",
            this.groupIndex,
            this.lineData.index,
            "additionaldiscountamount",
            this.inputNumber
          );
          this.$emit(
            "modify",
            this.groupIndex,
            this.lineData.index,
            "discount",
            ""
          );
        }
      } else {
        this.$emit(
          "modify",
          this.groupIndex,
          this.lineData.index,
          attr.prop,
          this.inputNumber
        );
      }

      this.cancel();
    },
    // 接受子级传过来的数据
    modify(groupIndex, lineIndex, prop, inputNumber) {
      this.$emit("modify", groupIndex, lineIndex, prop, inputNumber);
    },
    // 取消内联编辑
    cancel() {
      this.$emit("edit", "", "");
    },
    // 按钮是否展示
    showButton(btn) {
      // 展示父产品名称 - 只有捆绑包的非最高父级显示
      if (btn.click === "quotationInfo" && Number(this.lineData.level) > 1) {
        return true;
      }
      // 收起行按钮 - 展开状态展示
      if (btn.click === "hideQuotation" && this.expandLine) {
        return true;
      }
      // 展开行按钮 - 非展开状态展示
      if (btn.click === "expandQuotation" && !this.expandLine) {
        return true;
      }
      // 复制按钮 - 非捆绑包和捆绑包最高父级才展示
      if (btn.click === "copyQuotation" && Number(this.lineData.level) === 1) {
        return true;
      }
      // 删除按钮 - 非捆绑包和捆绑包最高父级才展示
      if (
        btn.click === "deleteQuotation" &&
        Number(this.lineData.level) === 1
      ) {
        return true;
      }
      // 查看折扣计划按钮 - nondiscountable为false,discountschedule有值时展示
      if (
        btn.click === "discount" &&
        this.lineData.nondiscountable === "false" &&
        this.lineData.discountschedule !== undefined &&
        this.lineData.discountschedule !== null &&
        this.lineData.discountschedule !== ""
      ) {
        return true;
      }
      // 配置产品 - 仅在捆绑包最高父级产品行显示
      if (
        btn.click === "configQuotation" &&
        Number(this.lineData.level) === 1 &&
        (this.lineData.configurationtype === "必须配置" ||
          this.lineData.configurationtype === "允许配置")
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    expandAllLine(nval) {
      this.expandLine = nval;
    },
    checkAll(nval) {
      //配置页面复选框置灰下全选不改变状态
      if (this.selectedd == false) {
        this.checked = nval;
      }
    },
    // 选中、取消数据选中
    checked(nval) {
      this.$emit("checkLine", nval, this.lineData, this.groupIndex);
    },
    radio() {
      this.$emit("radioLine", this.lineData.id, this.lineData);
    },
    lineData: {
      deep: true,
      handler(nval) {
        this.additionaldiscount = "";
        this.discountType = "";
        this.indentWidth =
          nval.childproduct && nval.childproduct.length > 0
            ? 22 * (Number(nval.level) - 1)
            : 22 * Number(nval.level);

        // 处理额外折扣字段
        if (
          nval["discount"] !== undefined &&
          nval["discount"] !== null &&
          nval["discount"] !== "0.00" &&
          nval["discount"] !== ""
        ) {
          this.additionaldiscount = nval["discount"];
          this.discountType = "%";
        } else if (
          nval["additionaldiscountamount"] !== undefined &&
          nval["additionaldiscountamount"] !== null &&
          nval["additionaldiscountamount"] !== ""
        ) {
          this.additionaldiscount = nval["additionaldiscountamount"];
          this.discountType = this.currency;
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
p {
  margin-bottom: 0;
}

.lookDiscount {
  width: 1124px;
  height: 369px;
  position: relative;
  .discountDialogTitle {
    padding: 10px;
    padding-left: 26px;
    width: 100%;
    font-size: 14px;
    color: #080707;
    font-weight: bold;
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    svg {
      width: 29px;
      height: 29px;
      margin-right: 6px;
    }
    span {
      line-height: 29px;
    }
  }
  .searchDis {
    width: 100%;
    margin: 33px 48px 0 0;
    padding-left: 26px;
    ::v-deep .el-input__inner {
      width: 258px;
      height: 30px;
      border: 1px solid #dddbda;
      border-radius: 3px;
      border-radius: 3px;
    }
  }
  .disFooter {
    padding-left: 26px;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 0;
    border-top: 1px solid #d8d8d8;
    display: flex;
    justify-content: flex-end;
    button {
      width: 64px;
      height: 34px;
      margin-top: 10px;
      border: 1px solid #d8d8d8;
      border-radius: 3px;
      border-radius: 3px;
    }
  }
}
.bansou {
  cursor: pointer;
  position: absolute;
  width: 26px;
  height: 34px;
  z-index: 999;
}
.quotation-table {
  padding: 10px;
  padding-left: 26px;
  ::v-deep .el-table__header-wrapper {
    border: 1px solid #dedcda;
  }
  width: 100%;
  clear: both;
  background: #fff;
  border-radius: 3px;
}
.tree_box {
  border-top: 1px solid #dedcda;

  .line_tree_box {
    .mainInfo {
      background: #fff;
      display: flex;
      align-items: center;
      // height: 38px;
      // line-height: 38px;

      .td {
        min-height: 38px;
        color: #080707;
        font-size: 14px;
        padding: 0 22px 0 10px;
        line-height: 38px;
        display: inline-flex;
        position: relative;
        cursor: pointer;
        border-left: 1px solid rgba(0, 0, 0, 0);

        .modifiedFields {
          color: #e68922;
        }

        .expandQuotation {
          flex: 0 0 12px;
          cursor: pointer;
          margin: 0px 6px 0 0;
          font-size: 16px;
        }

        .editImg {
          cursor: pointer;
          position: absolute;
          right: 5px;
          top: 0;

          .icon {
            width: 12px;
          }
        }

        .btn {
          margin-right: 8px;
          cursor: pointer;
        }

        .editBox {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 100;

          .buttons {
            position: absolute;
            text-align: center;
            background: #ffffff;
            width: 160px;
            box-shadow: 0 8px 13px 3px rgba(0, 0, 0, 0.15);
            border-radius: 0 0 3px 3px;
            border-radius: 0px 0px 3px 3px;

            .el-button {
              padding: 8px 10px;
              color: #006dcc;
            }

            .el-button--primary {
              color: #fff;
            }
          }
        }
      }

      .inlineblock-td {
        display: inline-block;
      }

      .buttonList {
        display: inline-block;
        padding-right: 10px;

        .icon {
          width: 12px;
          margin-top: 13px;
          cursor: pointer;
          display: inline-block;
        }
      }

      .tootip-span {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
      }

      .checkbox-span {
        // width: 30px;
        padding: 0;
        text-align: center;
        display: inline-block;
        border: none;
      }

      .number-span {
        // width: 40px;
        padding: 0;
        text-align: center;
        display: inline-block;
        border-left: 1px solid rgba(0, 0, 0, 0);
      }

      &:hover {
        background: #ececec;
      }
    }

    .moreInfoBox {
      padding: 0 80px 20px 80px;
      border-top: 1px solid #dedcda;

      .moreInfo {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;

        li {
          width: calc(100% / 3);
          display: inline-flex;
          flex-wrap: wrap;
          margin-top: 20px;

          p {
            width: 100%;
            margin-bottom: 10px;
            color: #666666;
            font-size: 14px;
            height: 14px;
            line-height: 14px;
            display: inline-block;
          }

          span {
            color: #080707;
            font-size: 12px;
            height: 12px;
            line-height: 12px;
          }
        }
      }
    }
  }

  .line_tree_box:hover {
    // background: #648ebe3d;
  }

  .input-number {
    width: 160px;
    position: absolute;
    display: inline-block;
    line-height: 38px;
    left: 0;

    ::v-deep .el-input__inner {
      width: 160px;
      height: 38px;
      text-align: left;
    }
  }
  .input-numberr {
    width: 160px;
    position: absolute;
    display: inline-block;
    line-height: 38px;
    left: 0;

    ::v-deep .el-input__inner {
      width: 160px;
      height: 38px;
      text-align: left;
      margin-left: 10px;
    }
  }
  ::v-deep .el-select {
    width: 110px;
    position: absolute;
    top: 0;
    left: 160px;

    .el-input__inner {
      height: 38px;
    }
  }
}
</style>
