<template>
  <!-- 导航栏 -->
  <div class="navigation">
    <!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px">
      <el-radio-button :label="false">展开<k/el-radio-button>
      <el-radio-button :label="true">收起</el-radio-button>
    </el-radio-group> -->
    <div class="el-menu" v-show="navmenu">
      <el-menu class="el-menu-vertical-demo" :collapse="isCollapse">
        <el-submenu
          v-for="(item, index) in navList"
          :key="item.id"
          :index="item.id"
          :class="{ active: isactive == index }"
          @click.native="navigaClick(index, item)"
        >
          <template slot="title">
            <span slot="title">{{ item.title || item.name }}</span>
          </template>
        </el-submenu>
      </el-menu>
    </div>
    <div class="collapse" @click="shrink">
      <svg class="icon" aria-hidden="true">
        <use :href="'#icon-navigation_xiala'"></use>
      </svg>
      <span
        class="nav_name"
        :style="{
          writingMode: language !== 'zh' ? 'vertical-lr' : 'horizontal-tb',
          transform: language !== 'zh' ? 'rotate(180deg)' : 'rotate(0deg)',
          marginLeft: language !== 'zh' ? '-3px' : '0',
        }"
        >{{ navName }}</span
      >
      <svg class="icon" aria-hidden="true">
        <use :href="'#icon-navigation_xiala'"></use>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navList: {
      type: Array,
      default: () => [],
    },
    navName: {
      type: String,
      default: "",
    },
    isactive: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      isCollapse: true,
      navmenu: false,
      language: this.$store.state.userInfoObj.language,
    };
  },
  watch: {
    //改变箭头指向
    navmenu() {
      if (this.navmenu === false) {
        let imgs = document.querySelectorAll(".collapse svg");
        imgs[0].style.transform = "rotate(180deg)";
        imgs[1].style.transform = "rotate(180deg)";
      } else {
        let imgs = document.querySelectorAll(".collapse svg");
        imgs[0].style.transform = "rotate(0deg)";
        imgs[1].style.transform = "rotate(0deg)";
      }
    },
  },
  mounted() {
    let imgs = document.querySelectorAll(".collapse svg");
    imgs[0].style.transform = "rotate(180deg)";
    imgs[1].style.transform = "rotate(180deg)";
  },

  methods: {
    navigaClick(index, item) {
      this.$emit("jump", index, item);
    },
    //显示隐藏
    shrink() {
      if (this.navmenu === false) {
        this.navmenu = true;
        this.isCollapse = false;
      } else {
        this.navmenu = false;
        this.isCollapse = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* 改变elementui 侧边栏移入颜色 */
.el-submenu:hover {
  background: #d8dde6 !important;
}
::v-deep .el-submenu:hover {
  background: #d8dde6 !important;
}
.el-submenu__title:hover {
  background: #d8dde6 !important;
}
.el-menu-item.is-active {
  background: #d8dde6 !important;
  color: #080707;
}
.el-submenu__title.is-active {
  background: #d8dde6 !important;
  color: #080707;
}
.el-menu {
  -webkit-animation: slide-down 0.5s ease-in;
  overflow: auto;
}
.active {
  background: #d8dde6 !important;
  color: #080707;
}
// .el-menu-item{
//     border-bottom:1px solid #DDDBDA;
// }
// .el-submenu__title {
//    border-bottom:1px solid #DDDBDA;
// }
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-submenu__title {
  border-bottom: 1px solid #dddbda;
}
::v-deep .el-submenu__title i {
  display: none;
}
.navigation {
  display: flex;
  width: 100%;
  height: 100%;
}
::v-deep .el-submenu__title {
  border: 1px solid #dddbda;
}
.collapse {
  width: 16px;
  background: #8199af;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    width: 11px;
    height: 11px;
  }
  span {
    font-size: 12px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    text-align: center;
  }
}
</style>